import { Module } from 'vuex-smart-module';
import { shopModule } from './modules/shop';
import { shopsModule } from './modules/shops';
import { userStatModule } from './modules/user';
import { driverStatModule } from './modules/driver';

export const root = new Module({
  modules: {
    shop: shopModule,
    shops: shopsModule,
    userStat: userStatModule,
    driverStat: driverStatModule
  }
});

export const { state, getters, mutations, actions, modules, plugins } = root.getStoreOptions();
