import { Store } from 'vuex';
import { Actions, Getters, Module, Mutations, createMapper } from "vuex-smart-module";
import { PortalUserStatResponse } from "~/types/api/user";


class UserStatState {
  userStat: PortalUserStatResponse | null = null;
}

class UserStatGetters extends Getters<UserStatState> {
  get userStat(): PortalUserStatResponse | null {
    return this.state.userStat;
  }
}

class UserStatMutations extends Mutations<UserStatState> {
  setUserStat(userStat: PortalUserStatResponse) {
    this.state.userStat = userStat;
  }
  clearUserStat() {
    this.state.userStat = null;
  }
}

class UserStatActions extends Actions<UserStatState, UserStatGetters, UserStatMutations> {
  store: Store<any> | null = null;
  $init(store: Store<any>): void {
    this.store = store;
  }
  async getUserStat(id: string): Promise<void> {
    try {
      const response = await this.store?.$axios.post(`/chompy-web/portal/user-stats`, {
        id: id
      });
      this.mutations.setUserStat(response?.data);
    } catch (e) {
      console.log(e);
    }
  }
}

export const userStatModule = new Module({
  state: UserStatState,
  getters: UserStatGetters,
  mutations: UserStatMutations,
  actions: UserStatActions
});

export const userStatMapper = createMapper(userStatModule);