import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';
import {
  Shop,
  Menu,
  MenuCategory,
  MenuItem,
  MenuItemSection,
  GetShopResponse,
  GetMenuItemResponse,
  ListMenusResponse,
  ListMenuCategoriesResponse,
  ListMenuItemSectionsResponse,
  PortalPartnerStatResponse
} from '~/types/api/shop';

class ShopState {
  shop: Shop = {};
  menuItem: MenuItem = {};
  menus: Menu[] = [];
  menuCategories: MenuCategory[] = [];
  menuItemSections: MenuItemSection[] = [];
  partnerStat: PortalPartnerStatResponse | null = null;
}

class ShopGetters extends Getters<ShopState> {
  get shop(): Shop {
    return this.state.shop;
  }
  get menuItem(): MenuItem {
    return this.state.menuItem;
  }
  get menus(): Menu[] {
    return this.state.menus;
  }
  get menuCategories(): MenuCategory[] {
    return this.state.menuCategories;
  }
  get menuItemSections(): MenuItemSection[] {
    return this.state.menuItemSections;
  }
  get partnerStat(): PortalPartnerStatResponse | null {
    return this.state.partnerStat;
  }
}

class ShopMutations extends Mutations<ShopState> {
  setShop(shop: Shop) {
    this.state.shop = shop;
  }
  setMenuItem(menuItem: MenuItem) {
    this.state.menuItem = menuItem;
  }
  setMenus(menus: Menu[]) {
    this.state.menus = menus;
  }
  setMenuCategories(menuCategories: MenuCategory[]) {
    this.state.menuCategories = menuCategories.filter(i => i);
  }
  setMenuItemSections(menuItemSections: MenuItemSection[]) {
    this.state.menuItemSections = menuItemSections.filter(i => i);
  }
  setPartnerStat(partnerStat: PortalPartnerStatResponse) {
    this.state.partnerStat = partnerStat;
  }
  clearShop() {
    this.state.shop = {};
  }
  clearMenuItem() {
    this.state.menuItem = {};
  }
  clearMenus() {
    this.state.menus = [];
  }
  clearMenuCategories() {
    this.state.menuCategories = [];
  }
  clearMenuItemSections() {
    this.state.menuItemSections = [];
  }
  clearPartnerStat() {
    this.state.partnerStat = null;
  }
}

class ShopActions extends Actions<ShopState, ShopGetters, ShopMutations, ShopMutations> {
  store: Store<any> | null = null;
  $init(store: Store<any>): void {
    this.store = store;
  }
  async getShop(shopId: string): Promise<void> {}
  async getMenuItem(shopId: string, menuItemId: string): Promise<void> {}
  async listMenus(shopId: string): Promise<void> {}
  async listMenuCategories({ shopId, menuId }: { shopId: string; menuId: string }): Promise<void> {}
  async listMenuItemSections({ shopId, menuId }: { shopId: string; menuId: string }): Promise<void> {}
  async getPartnerStat(id: string): Promise<void> {
    try {
      const response = await this.store?.$axios.post(`/chompy-web/portal/partner-stats`, {
        id: id
      });
      this.mutations.setPartnerStat(response?.data);
    } catch (e) {
      console.log(e);
    }
  }
  clearShop() {
    this.mutations.clearShop();
  }
  clearMenus() {
    this.mutations.clearMenus();
  }
  clearMenuItemSections() {
    this.mutations.clearMenuItemSections();
  }
}

export const shopModule = new Module({
  state: ShopState,
  getters: ShopGetters,
  mutations: ShopMutations,
  actions: ShopActions
});

export const shopMapper = createMapper(shopModule);
