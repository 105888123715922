
import { Store } from 'vuex';
import { Actions, Getters, Module, Mutations, createMapper } from "vuex-smart-module";
import { PortalDriverStatResponse } from "~/types/api/driver";


class DriverStatState {
  driverStat: PortalDriverStatResponse | null = null;
}

class DriverStatGetters extends Getters<DriverStatState> {
  get driverStat(): PortalDriverStatResponse | null {
    return this.state.driverStat;
  }
}

class DriverStatMutations extends Mutations<DriverStatState> {
  setDriverStat(driverStat: PortalDriverStatResponse) {
    this.state.driverStat = driverStat;
  }
  clearDriverStat() {
    this.state.driverStat = null;
  }
}

class DriverStatActions extends Actions<DriverStatState, DriverStatGetters, DriverStatMutations> {
  store: Store<any> | null = null;
  $init(store: Store<any>): void {
    this.store = store;
  }
  async getDriverStat(id: string): Promise<void> {
    try {
      const response = await this.store?.$axios.post(`/chompy-web/portal/driver-stats`, {
        id: id
      });
      this.mutations.setDriverStat(response?.data);
    } catch (e) {
      console.log(e);
    }
  }
}

export const driverStatModule = new Module({
  state: DriverStatState,
  getters: DriverStatGetters,
  mutations: DriverStatMutations,
  actions: DriverStatActions
});

export const driverStatMapper = createMapper(driverStatModule);