import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _577406ac = () => interopDefault(import('../src/pages/shops/index.vue' /* webpackChunkName: "pages/shops/index" */))
const _8feca072 = () => interopDefault(import('../src/pages/shops/featured/index.vue' /* webpackChunkName: "pages/shops/featured/index" */))
const _3a31fbfd = () => interopDefault(import('../src/pages/shops/new/index.vue' /* webpackChunkName: "pages/shops/new/index" */))
const _468d0d6c = () => interopDefault(import('../src/pages/seeyouagain/drivers/_id/index.vue' /* webpackChunkName: "pages/seeyouagain/drivers/_id/index" */))
const _159a4a08 = () => interopDefault(import('../src/pages/seeyouagain/shops/_id/index.vue' /* webpackChunkName: "pages/seeyouagain/shops/_id/index" */))
const _c16534f2 = () => interopDefault(import('../src/pages/seeyouagain/users/_id/index.vue' /* webpackChunkName: "pages/seeyouagain/users/_id/index" */))
const _80c23da6 = () => interopDefault(import('../src/pages/shops/_shopId/index.vue' /* webpackChunkName: "pages/shops/_shopId/index" */))
const _7a2f3604 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/shops",
    component: _577406ac,
    name: "shops"
  }, {
    path: "/shops/featured",
    component: _8feca072,
    name: "shops-featured"
  }, {
    path: "/shops/new",
    component: _3a31fbfd,
    name: "shops-new"
  }, {
    path: "/seeyouagain/drivers/:id",
    component: _468d0d6c,
    name: "seeyouagain-drivers-id"
  }, {
    path: "/seeyouagain/shops/:id",
    component: _159a4a08,
    name: "seeyouagain-shops-id"
  }, {
    path: "/seeyouagain/users/:id",
    component: _c16534f2,
    name: "seeyouagain-users-id"
  }, {
    path: "/shops/:shopId",
    component: _80c23da6,
    name: "shops-shopId"
  }, {
    path: "/",
    component: _7a2f3604,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
