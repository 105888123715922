import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';
import { Shop, ListAreaShopRequest, ListFeaturedShopRequest, ListShopsResponse } from '~/types/api/shop';

class ShopsState {
  areaShops: Shop[] = [];
  featuredShops: Shop[] = [];
  newShops: Shop[] = [];
  page: number = 1;
  hasNext: boolean = true;
}

class ShopsGetters extends Getters<ShopsState> {
  get areaShops(): Shop[] {
    return this.state.areaShops ?? [];
  }
  get featuredShops(): Shop[] {
    return this.state.featuredShops ?? [];
  }
  get newShops(): Shop[] {
    return this.state.newShops ?? [];
  }
  get page(): number {
    return this.state.page ?? 1;
  }
  get hasNext(): boolean {
    return this.state.hasNext ?? true;
  }
}

class ShopsMutations extends Mutations<ShopsState> {
  setAreaShops(shops: Shop[]) {
    this.state.areaShops = this.state.areaShops.concat(shops);
    this.state.page += 1;
    this.state.hasNext = shops.length > 0;
  }
  clearAreaShops() {
    this.state.areaShops = [];
    this.state.page = 1;
    this.state.hasNext = true;
  }
  setFeaturedShops(shops: Shop[]) {
    this.state.featuredShops = shops;
  }
  clearFeaturedShops() {
    this.state.featuredShops = [];
  }
  setNewShops(shops: Shop[]) {
    this.state.newShops = shops;
  }
  clearNewShops() {
    this.state.newShops = [];
  }
}

class ShopsActions extends Actions<ShopsState, ShopsGetters, ShopsMutations, ShopsMutations> {
  store: Store<any> | null = null;
  $init(store: Store<any>): void {
    this.store = store;
  }
  async listAreaShops(params: ListAreaShopRequest): Promise<void> {
  }
  async listFeaturedShops(params: ListFeaturedShopRequest): Promise<void> {
  }
  async listNewShops(): Promise<void> {
  }
  clearAreaShops() {
    this.mutations.clearAreaShops();
  }
  clearFeaturedShops() {
    this.mutations.clearFeaturedShops();
  }
  clearNewShops() {
    this.mutations.clearNewShops();
  }
}

export const shopsModule = new Module({
  state: ShopsState,
  getters: ShopsGetters,
  mutations: ShopsMutations,
  actions: ShopsActions
});

export const shopsMapper = createMapper(shopsModule);
