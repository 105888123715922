import { Plugin, Context } from '@nuxt/types';
import logger from '~/util/logger';

const axios: Plugin = ({ $axios }: Context) => {
  $axios.defaults.baseURL = process.env.API_BASE_URL;

  $axios.defaults.validateStatus = (status) => {
    return status < 500
  };

  $axios.onResponse(response => {
    logger.debug('$axios.onResponse', response);
  });

  $axios.onError(error => {
    if (error.response?.status === 404) {
      logger.info('$axios.onError', error);
    } else {
      logger.error('$axios.onError', error);
    }
    if (error.response?.status === 404 && process.client) {
      window.location.href = "/404.html";
    }
  });
};

export default axios;
